import { zodResolver } from '@hookform/resolvers/zod'
import * as zod from 'zod'
import { z } from 'zod'

export const schemaDanish = zod.object({
	email: zod
		.string()
		.min(1, 'Tjek at du har skrevet din korrekte e-mail')
		.email('Tjek at din e-mail er gyldig')
		.transform(value => value.toLowerCase()),
	password: zod
		.string({ required_error: 'Password is required' })
		.min(6, { message: 'Password is too short' })
		.max(100, { message: 'Password is too long' }),
	redirectTo: z.string().optional(),
	remember: z.boolean().optional(),
})

export const schemaEnglish = zod.object({
	email: zod
		.string()
		.min(1, 'The email is required')
		.email('Please enter a valid email')
		.transform(value => value.toLowerCase()),
	password: zod
		.string({ required_error: 'Password is required' })
		.min(6, { message: 'Password is too short' })
		.max(100, { message: 'Password is too long' }),
	redirectTo: z.string().optional(),
	remember: z.boolean().optional(),
})

export type FormData = zod.infer<typeof schemaEnglish>

export const resolverDanish = zodResolver(schemaDanish)
export const resolverEnglish = zodResolver(schemaEnglish)
