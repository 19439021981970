import { useTranslation } from '#node_modules/react-i18next'
import { Link } from '@remix-run/react'
import React from 'react'
import { useRemixFormContext } from 'remix-hook-form'
import { InputField } from '#app/components/common/forms/input.tsx'
import PlentyBody from '#app/components/typography/plenty-body.tsx'

export const LoginForm = () => {
	const { register, getFieldState, watch } = useRemixFormContext()
	let { t } = useTranslation('login')
	const emailValue = watch('email')
	return (
		<>
			<div className="mb-4 space-y-4">
				<InputField
					{...register('email')}
					placeholder={t('email_placeholder')}
					error={getFieldState('email').error}
					isValid={
						getFieldState('email').isTouched && !getFieldState('email').invalid
					}
				/>
				<InputField
					{...register('password')}
					type="password"
					placeholder={t('password_placeholder')}
					error={getFieldState('password').error}
					isValid={
						getFieldState('password').isTouched &&
						!getFieldState('password').invalid
					}
				/>
				<div className="flex flex-row justify-end">
					<Link
						to={
							emailValue
								? `/forgot-password?email=${emailValue}`
								: '/forgot-password'
						}
					>
						<PlentyBody size="md" className="text-light-grey-90 underline">
							{t('forgot_password_text')}
						</PlentyBody>
					</Link>
				</div>
				<input {...register('redirectTo')} type="hidden" />
			</div>
		</>
	)
}
