import { Link, useActionData, useSearchParams } from '@remix-run/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { RemixFormProvider, useRemixForm } from 'remix-hook-form'
import type zod from 'zod'
import PlentyBody from '#app/components/typography/plenty-body.tsx'
import PlentyHeading from '#app/components/typography/plenty-heading.tsx'
import { Button } from '#app/components/ui/button.tsx'
import {
	Card,
	CardContent,
	CardFooter,
	CardHeader,
} from '#app/components/ui/card.tsx'
import { LoadingSpinner } from '#app/components/ui/spinner.tsx'
import {
	resolverDanish,
	resolverEnglish,
	schemaDanish,
	schemaEnglish,
} from '#app/features/login/form.ts'
import { LoginForm } from '#app/features/login/login-form.tsx'
import { type action } from '#app/routes/_auth+/login.tsx'
import { useIsPending } from '#app/utils/misc.ts'

export const Login = () => {
	let { i18n, t } = useTranslation('login')
	const isPending = useIsPending()
	const actionData = useActionData<typeof action>()
	const [searchParams] = useSearchParams()
	const redirectTo = searchParams.get('redirectTo')

	const schema = i18n.language === 'da' ? schemaDanish : schemaEnglish
	const resolver = i18n.language === 'da' ? resolverDanish : resolverEnglish
	const methods = useRemixForm<zod.infer<typeof schema>>({
		resolver,
		mode: 'all',
		reValidateMode: 'onChange',
		defaultValues: {
			email: '',
			password: '',
			redirectTo: redirectTo ?? '',
		},
	})

	return (
		<Card>
			<CardHeader className="py-6">
				<PlentyHeading as={'h7'}>{t('login_header')}</PlentyHeading>
			</CardHeader>
			<CardContent>
				<RemixFormProvider {...methods}>
					<form onSubmit={methods.handleSubmit}>
						{/* TODO: Figure it out the FormData for the honeypot */}
						{/* <HoneypotInputs /> */}
						<LoginForm />
						<Button
							size="primary"
							type="submit" 
							disabled={isPending}
							fullWidth
						>
							{isPending && <LoadingSpinner className="mr-2" />}
							{t('login_button')}
						</Button>
					</form>
				</RemixFormProvider>
			</CardContent>
			<CardFooter className="flex flex-col justify-center pt-6">
				<div className="flex flex-row">
					<PlentyBody size="lg">{t('do_not_have_account_question')}</PlentyBody>{' '}
					<Link
						to={
							redirectTo
								? `/signup?${encodeURIComponent(redirectTo)}`
								: '/signup'
						}
					>
						<PlentyBody size="lg" className="ml-2 underline">
							{t('sign_up_link_text')}
						</PlentyBody>
					</Link>
				</div>
				<div className="flex flex-row pt-6">
					{actionData &&
						actionData.errors &&
						actionData.errors.redirectTo?.message ===
							'incorrect_credentials' && (
							<PlentyBody size="md" className="text-center text-red-90">
								{t('incorrect_credentials')}
							</PlentyBody>
						)}
				</div>
			</CardFooter>
		</Card>
	)
}
