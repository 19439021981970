import {
	json,
	type ActionFunctionArgs,
	type LoaderFunctionArgs,
	type MetaFunction,
} from '@remix-run/node'
import React from 'react'
import { getValidatedFormData } from 'remix-hook-form'
import type zod from 'zod'
import { GeneralErrorBoundary } from '#app/components/error-boundary.tsx'
import { createToastHeaders } from '#app/cookies/toast.server.ts'
import {
	resolverDanish,
	resolverEnglish,
	schemaDanish,
	schemaEnglish,
} from '#app/features/login/form.ts'
import { Login } from '#app/features/login/login.tsx'
import { login, requireAnonymous } from '#app/server/auth.server.ts'
import { handleNewSession } from '#app/server/login.server.ts'
import i18next from '#app/server/translation/i18next.server.ts'

export async function loader({ request }: LoaderFunctionArgs) {
	await requireAnonymous(request)
	return json({})
}

export async function action({ request }: ActionFunctionArgs) {
	await requireAnonymous(request)
	// TODO: Figure it out the honeypot
	// const formData = await request.formData()

	let lng = await i18next.getLocale(request)

	const schema = lng === 'da' ? schemaDanish : schemaEnglish
	const resolver = lng === 'da' ? resolverDanish : resolverEnglish

	try {
		const { receivedValues, errors, data } = await getValidatedFormData<
			zod.infer<typeof schema>
		>(request, resolver)

		if (errors) {
			return json({
				errors,
				receivedValues,
			})
		}
		const session = await login(data)
		if (!session) {
			return json({
				errors: {
					redirectTo: {
						message: 'incorrect_credentials',
					},
					email: { message: '' },
					password: { message: '' },
				},
				values: { ...data, password: '' },
			})
		}
		return handleNewSession({
			request,
			session,
			remember: false,
			redirectTo: data?.redirectTo,
		})
	} catch (error) {
		return json(
			{ errors: { redirectTo: { message: '' } } },
			{
				headers: await createToastHeaders({
					description: 'Something went wrong',
					type: 'error',
				}),
			},
		)
	}
}

export default function LoginPage() {
	return <Login />
}

export const meta: MetaFunction = () => {
	return [{ title: 'Login to Plenty&' }]
}

export function ErrorBoundary() {
	return <GeneralErrorBoundary />
}
